import React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

import Layout from "../components/layout";
//import document from '../swagger/api.yaml';
import document from '../swagger/new.yaml';

global.Buffer = global.Buffer || require('buffer').Buffer;

const IndexPage = () => (
  <Layout>
    {/* <SEO title={'Title'} keywords={[`gatsby`, `swagger`, `react`, `api`, `documentation`]} /> */}
    <SwaggerUI spec={document} />
  </Layout>
)

export default IndexPage;